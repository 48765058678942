import styled from 'styled-components';
import { Color, FontFamily, FontWeight, FontSize } from '../obj.constants';
import { Link } from 'react-router-dom';
import { ButtonProps } from './button.component';

export const ButtonHeight = 48;
export const ButtonWidth = 160;

const BUTTON_COLORS = {
  primary: {
    color: Color.White,
    backgroundColor: Color.Primary,
  },
  secondary: {
    color: Color.Primary,
    backgroundColor: Color.White,
  },
};

export const ButtonStyled = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props: ButtonProps) => props.type ? BUTTON_COLORS[props.type].color : Color.Primary};
  border: 1px solid ${Color.Primary};
  background-color: ${(props: ButtonProps) => props.type ? BUTTON_COLORS[props.type].backgroundColor : Color.White};
  ${(props: ButtonProps) => !props.wrap && `min-width: ${ButtonWidth}px`};
  padding: 0 16px;
  height: ${ButtonHeight}px;
  border-radius: 48px;
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Medium};
  text-transform: uppercase;
  opacity: ${(props: ButtonProps) => (props.disabled ? 0.5 : 1)};
  ${(props: ButtonProps) => props.disabled && 'pointer-events: none;'}
`;
