// Images
import Challenge from '../../assets/images/img-challenge.png';
import HomeBg from '../../assets/images/home-bg.png';
import Logo from '../../assets/images/logo.svg';

// Dog images
import Salsicha from '../../assets/images/img_dog_salsicha.svg';
import Bob from '../../assets/images/img_dog_bob.svg';
import Taki from '../../assets/images/img_dog_taki.svg';
import Ceviche from '../../assets/images/img_dog_ceviche.svg';
import Poodle from '../../assets/images/img_dog_poodle.svg';

// Icons
import Back from '../../assets/icons/ic_back.svg';

// Media icons
import Facebook from '../../assets/icons/media/ic_facebook.png';
import Instagram from '../../assets/icons/media/ic_instagram.png';
import Medium from '../../assets/icons/media/ic_medium.png';


// Placeholders
// TODO

export const Asset = {
  Icon: {
    Back,
    Media: {
      Facebook,
      Instagram,
      Medium,
    },
  },
  Image: {
    Dog: {
      Bob,
      Ceviche,
      Poodle,
      Salsicha,
      Taki,
    },
    Challenge,
    HomeBg,
    Logo,
  },
};
