import styled from 'styled-components';
import { Breakpoint } from '../obj.constants';
import { GridSettings } from '../obj.grid';

export interface HeroStyledProps {
  url?: any;
}

export const Hero = styled.div`
  background-image: ${(props: HeroStyledProps) => `url(${props.url})`};
  background-size: 100% 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding-left: ${GridSettings.outerMargin}rem;
  padding-right: ${GridSettings.outerMargin}rem;

  height: 320px;

  @media (min-width: ${Breakpoint.lg}) {
    padding-left: 10%;
    padding-right: 40%;

    height: 440px;
  }
`;
