import { Asset } from '../obj.asset';

const TrailColors = ['#EAF5F3', '#FDF1F5', '#FFFADD', '#F8E7E3', '#DCE9F3'];

const TrailImages = [
  Asset.Image.Dog.Ceviche,
  Asset.Image.Dog.Bob,
  Asset.Image.Dog.Poodle,
  Asset.Image.Dog.Salsicha,
  Asset.Image.Dog.Taki,
];

export const mapTrailConstants = (id: number) => {
  return {
    color: TrailColors[id % TrailColors.length],
    image: TrailImages[id % TrailImages.length],
  }
};
