import React, { FC } from 'react';
import { HomeCourseTitle, Body } from '../atm.typography';
import { VSeparator, HBox } from '../obj.box';
import { Button } from '../atm.button';

import { CourseItemStyled, CourseItemImageStyled, CourseItemContentStyled } from './course-item.component.style';

export interface CourseItemProps {
  name: string;
  description: string;
  color: string;
  image: any;
  to: string;
}

export const CourseItem: FC<CourseItemProps> = props => (
  <CourseItemStyled color={props.color}>
    <CourseItemImageStyled url={props.image} color={props.color} />
    <CourseItemContentStyled>
      <HomeCourseTitle>{props.name}</HomeCourseTitle>
      <Body>{props.description}</Body>
      <VSeparator responsive={true} />
      <HBox>
        <Button to={props.to} type={'primary'}>Começar</Button>
      </HBox>
      <VSeparator />
    </CourseItemContentStyled>
  </CourseItemStyled>
);
