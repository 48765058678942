import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as QueryString from 'query-string';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { VSeparator } from '../../atomic/obj.box';
import { Col, Grid, Row } from '../../atomic/obj.grid';
import { mapTrailConstants } from '../../atomic/obj.trail-utils';
import { Footer } from '../../atomic/org.footer';
import { Header } from '../../atomic/org.header/header.component';
import { Query } from '../../generated/graphql';
import { ListComponent } from './list.section';
import { VideoSection } from './video.section';

export const VideoPage: React.FC<RouteComponentProps<{
  id: string;
  videoId: string;
}>> = (props) => {
  const videoId = QueryString.parse(props.location.search).videoId as string;

  //TODO create graphql interface for schema
  const { data } = useQuery<{ Course: Query['Course'] }, { id: number }>(COURSE_QUERY, {
    variables: { id: +props.match.params.id },
    skip: !props.match.params.id,
  });
  const trail = data?.Course;
  const lessons = trail?.lessons;

  useEffect(() => {
    if (!videoId && lessons?.[0]?.id) {
      props.history.replace(`/course/${props.match.params.id}?videoId=${lessons[0].id}`);
    }
  }, [lessons, props.history, props.match.params.id, videoId]);

  const trailConstants = mapTrailConstants(+props.match.params.id);

  const handleVideoEnd = () => {
    const findIndex = lessons?.findIndex((it) => it.id === +videoId);
    if (findIndex && findIndex !== -1 && lessons?.[findIndex + 1]) {
      props.history.push(`/course/${props.match.params.id}?videoId=${lessons[findIndex + 1].id}`);
    }
  };

  return (
    <>
      <Header />
      <VSeparator responsive={true} />
      <Grid fluid={true} xsExpanded={true}>
        <Row>
          <Col xs={12} md={8}>
            <VideoSection onVideoEnd={handleVideoEnd} videoId={videoId} trail={trail} />
          </Col>

          <Col xs={12} md={4}>
            <ListComponent
              trailId={props.match.params.id}
              items={lessons}
              videoId={videoId}
              title={trail?.title}
              highlightColor={trailConstants.color}
            />
            <VSeparator />
          </Col>
        </Row>
      </Grid>

      <Grid fluid={true} expanded={true}>
        <Row>
          <Col xs={12}>
            <Footer />
          </Col>
        </Row>
      </Grid>
    </>
  );
};

const COURSE_QUERY = gql`
  query Course($id: Int!) {
    Course(id: $id) {
      id
      title
      caption
      about
      lessons {
        id
        description
        duration
        title
        thumbnail
      }
    }
  }
`;
