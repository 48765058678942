import React from 'react';
import { H3 } from '../atm.typography';
import { TabItemStyled, TabStyled } from './tabs.component.style';

interface TabProps {
  tabItems: string[];
  activeIndex: number
  onIndexChanged: (index: number) => void;
}

export const Tabs: React.FC<TabProps> = props => {

  const { tabItems, activeIndex, onIndexChanged } = props;

  const handleTabItemTap = index => () => {
    onIndexChanged(index);
  }

  return (
      <TabStyled>
        {tabItems.map((item, index) => {
          const selected = index === activeIndex;
          return (
            <TabItemStyled
              key={index}
              selected={selected}
              onClick={handleTabItemTap(index)}
            >
              <H3>{item}</H3>
            </TabItemStyled>
          );
        })}
      </TabStyled>
  );

}
