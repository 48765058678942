import * as React from 'react';
import { IcSvgStyled } from './ic.component.style';
import { IcDefaultProps, IcProps } from './ic.utils';

export const IcThumbDown: React.FC<IcProps> = props => (
  <IcSvgStyled {...props}>
    <path
        d="M19.85 2H6.483a3.15 3.15 0 00-3.098 2.583l-1.334 7.35A3.15 3.15 0 005.15 15.65h4.788l-.588 1.501A4.336 4.336 0 0013.434 23c.413 0 .786-.243.956-.62l2.992-6.73h2.468A3.15 3.15 0 0023 12.5V5.15A3.15 3.15 0 0019.85 2zm-4.2 12.38l-2.856 6.425a2.237 2.237 0 01-1.45-2.918l.557-1.502a2.1 2.1 0 00-1.964-2.835H5.15a1.05 1.05 0 01-.808-.378 1.05 1.05 0 01-.242-.861l1.334-7.35a1.05 1.05 0 011.05-.861h9.167v10.28zm4.2-.83c.58 0 1.05-.47 1.05-1.05V5.15c0-.58-.47-1.05-1.05-1.05h-2.1v9.45h2.1z"
        fill="inherit"
        fillRule="evenodd"
      />
  </IcSvgStyled>
);

IcThumbDown.defaultProps = { ...IcDefaultProps };
