import YouTube from 'react-youtube';
import styled from 'styled-components';

export const YoutubeStyled = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

// 540 x 960
const VIDEO_RATIO = '56.25%';

export const YoutubeWrapperStyled = styled.div`
  position: relative;
  padding-bottom: ${VIDEO_RATIO};
  padding-top: 25px;
  height: 0;
`;
