import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Body, H1, H2, H3, H4 } from '../atm.typography';
import { Link } from '../atm.button';

export interface MarkdownProps {
  children: string;
}

const HeadingLevelToComponent = props => {
  const mapper: { [key: number]: JSX.Element } = {
    1: <H1 {...props} />,
    2: <H2 {...props} />,
    3: <H3 {...props} />,
    4: <H4 {...props} />,
  };
  return mapper[props.level];
};

// [foo] text issue workaround: https://github.com/rexxars/react-markdown/issues/115
const LinkReference: React.FC<any> = props => {
  return (
    <>
      { !props.href
        ? <>[${props.children[0]}]</>
        : <a href={props.$ref}>{props.children}</a>
      }
    </>
  );
};

export const Markdown: React.FC<MarkdownProps> = props => {

  // nodes: https://github.com/rexxars/react-markdown#node-types
  const renderers = {
    text: Body,
    link: Link,
    heading: props => HeadingLevelToComponent(props),
    linkReference: LinkReference,
  }

  return (
    <ReactMarkdown renderers={renderers}>
      {props.children}
    </ReactMarkdown>
  );
};
