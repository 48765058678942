import styled from 'styled-components';
import { Breakpoint, ResponsiveSpacing, Spacing } from '../obj.constants';

interface VSeparatorProps {
  responsive?: boolean;
  half?: boolean;
}

export const VSeparator = styled.div`
  height: ${(props: VSeparatorProps) => (props.half ? Spacing.XSmall : Spacing.Large)}px;

  @media (min-width: ${Breakpoint.md}) {
    ${(props: VSeparatorProps) => props.responsive &&
      `height: ${(props.half ? Spacing.XSmall : Spacing.Large) * ResponsiveSpacing}px;`
    }
  }
`;
