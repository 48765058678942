import React from 'react';
import { BadgeStyled, BadgeTextStyled } from './badge.component.style';

export interface BadgeProps {
  text: string;
  type: 'light' | 'dark';
}

export const Badge: React.FC<BadgeProps> = props => (
  <BadgeStyled {...props}>
    <BadgeTextStyled {...props}>{props.text}</BadgeTextStyled>
  </BadgeStyled>
)
