import styled, { css } from 'styled-components';
import { Color, FontFamily, FontWeight, FontSize } from '../obj.constants';

const Css = css`
  color: ${Color.Accessory};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Small};
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const LinkStyled = styled.div`
  ${Css}
`;
