import styled from 'styled-components';
import { Spacing } from '../../atomic/obj.constants';
import { GridSettings } from '../obj.grid';

interface BoxProps {
  // src: https://www.w3schools.com/cssref/css3_pr_align-items.asp
  hAlign?: 'stretch'| 'center'| 'flex-start'| 'flex-end'| 'baseline'| 'initial'| 'inherit';
  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  vAlign?: 'flex-start'| 'flex-end'| 'center'| 'space-between'| 'space-around'| 'initial'| 'inherit';
}

export interface HBoxProps extends BoxProps {
  noGrow?: boolean;
  double?: boolean;
}

const HBoxStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`;

export const HBox: any = HBoxStyled;
HBox.displayName = 'HBox';

HBox.Item = styled.div`
  flex-direction: column;
  display: flex;
  ${(props: HBoxProps) => !props.noGrow && (props.double ? 'flex: 2' : 'flex: 1') };
  justify-content: ${(props: HBoxProps) => props.vAlign ? props.vAlign : 'flex-start'};
  align-items: ${(props: HBoxProps) => props.hAlign ? props.hAlign : 'stretch'};
`;
HBox.Item.displayName = 'HBox.Item';

HBox.Separator = styled.div`
  width: ${Spacing.Large}px;
`;

HBox.Separator.displayName = 'HBox.Separator';

export interface VBoxProps {
  xsNoGutter?: boolean;
  smNoGutter?: boolean;
  mdNoGutter?: boolean;
  lgNoGutter?: boolean;
  noGutter?: boolean;
  bgColor?: string;
}

const VBoxStyled = styled.div`
  padding-left: ${(props: VBoxProps) => props.noGutter ? 0 : `${Spacing.Large}px`};
  padding-right: ${(props: VBoxProps) => props.noGutter ? 0 : `${Spacing.Large}px`};
  background-color: ${(props: VBoxProps) => props.bgColor || 'transparent'};

  ${(props: VBoxProps) => props.smNoGutter && `
    @media screen and (max-width: ${GridSettings.breakpoints.sm}rem) {
      padding-left: 0;
      padding-right: 0;
    }`
  };
  ${(props: VBoxProps) => props.smNoGutter && `
    @media screen and (min-width: ${GridSettings.breakpoints.sm}rem) and (miax-width: ${GridSettings.breakpoints.md}rem) {
      padding-left: 0;
      padding-right: 0;
    }`
  };
  ${(props: VBoxProps) => props.mdNoGutter && `
    @media screen and (min-width: ${GridSettings.breakpoints.md}rem) and (max-width: ${GridSettings.breakpoints.lg}rem) {
      padding-left: 0;
      padding-right: 0;
    }`
  };
  ${(props: VBoxProps) => props.lgNoGutter && `
    @media screen and (min-width: ${GridSettings.breakpoints.lg}rem) {
      padding-left: 0;
      padding-right: 0;
    }`
  };

`;

export const VBox = VBoxStyled;
VBox.displayName = 'VBox';

