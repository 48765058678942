import styled, { css } from 'styled-components';
import { Breakpoint, Color, Spacing, ResponsiveSpacing } from '../obj.constants';

const SMALL_IMG = '240px';
const MD_IMG = '430px';

interface CourseItemImageStyledProps {
  url: string;
  color: string;
}

export const CircularBgCss = css`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 50%;
    background-color: ${(props: CourseItemImageStyledProps) => props.color || Color.GrayXLight};
  }
`;

export const CourseItemImageStyled = styled.div`
  width: ${SMALL_IMG};
  height: ${SMALL_IMG};
  flex-shrink: 0;
  align-self: center;
  background-image: ${(props: CourseItemImageStyledProps) => `url(${props.url})`};
  background-position: center;
  background-size: contain;

  ${CircularBgCss}

  @media (min-width: ${Breakpoint.lg}) {
    width: ${MD_IMG};
    height: ${MD_IMG};
  }
`;

export const CourseItemContentStyled = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  height: 340px;

  @media (min-width: ${Breakpoint.lg}) {
    height: 500px;
  }
`;

interface ColorProps {
  color?: string;
}

export const BgShapeCss = css`
  position: relative;

  &::before {
    content: '';
    background-color: ${(props: ColorProps) => props.color || Color.GrayXLight};
    position: absolute;
    top: 45%;
    bottom: 0;
    left: -500px;
    right: 60%;
    z-index: -1;
  }

  &:nth-child(even) {
    &::before {
      left: auto;
      right: -500px;
      left: 40%;
    }
  }

  @media (min-width: ${Breakpoint.lg}) {
    &::before {
      top: 0;
      right: 80%;
    }

    &:nth-child(even) {
      &::before {
        left: 70%;
      }
    }
  }
`;

export const CourseItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 54px;

  ${BgShapeCss}

  @media (min-width: ${Breakpoint.lg}) {
    flex-direction: row-reverse;
    margin-bottom: ${54 * ResponsiveSpacing}px;

    & ${CourseItemImageStyled} {
      margin-left: ${Spacing.XLarge * ResponsiveSpacing}px;
    }

    &:nth-child(even) {
      flex-direction: row;

      & ${CourseItemImageStyled} {
        margin-left: 0;
        margin-right: ${Spacing.XLarge * ResponsiveSpacing}px;
      }
    }

  }
`;
