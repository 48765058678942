import React from 'react';

import { HBox, VBox } from '../obj.box';
import { Body } from '../atm.typography';
import { SocialMedia, Color } from '../obj.constants';
import { Row, Col } from '../obj.grid';
import { VSeparator } from '../obj.box';
import { Asset } from '../obj.asset';
import { Divider } from '../atm.divider';
import { Icon } from '../atm.icon';

import { Contact } from './contact.component';

export const Footer: React.FC = () => (
  <VBox noGutter={true} bgColor={Color.GrayXLight}>
    <Divider />
    <VSeparator responsive={true} />
    <VSeparator responsive={true} />

    <Row between='sm'>
      <Col xsOffset={1} xs={10} sm={4}>
        <Body>Esse curso foi desenvolvido pela <b>Taqtile</b></Body>
        <VSeparator half={true} responsive={true} />

        <HBox>
          <HBox.Item noGrow={true}>
            <a href={SocialMedia.Instagram} target='_blank' rel='noreferrer noopener'>
              <Icon src={Asset.Icon.Media.Instagram} alt='Instagram da Taqtile' />
            </a>
          </HBox.Item>
          <HBox.Separator />
          <HBox.Item noGrow={true}>
            <a href={SocialMedia.Facebook} target='_blank' rel='noreferrer noopener'>
              <Icon src={Asset.Icon.Media.Facebook} alt='Facebook da Taqtile' />
            </a>
          </HBox.Item>
          <HBox.Separator />
          <HBox.Item noGrow={true}>
            <a href={SocialMedia.Medium} target='_blank' rel='noreferrer noopener'>
              <Icon src={Asset.Icon.Media.Medium} alt='Medium da Taqtile' />
            </a>
          </HBox.Item>
        </HBox>
        <VSeparator responsive={true} />
      </Col>

      <Col xsOffset={1} xs={10} smOffset={0} sm={3}>
        <Contact title={'Trabalhe conosco'} url={'careers@taqtile.com.br'} />
        <VSeparator responsive={true} />
      </Col>

      <Col xsOffset={1} xs={10}smOffset={0} sm={3}>
        <Contact title={'Ficou com alguma dúvida?'} url={'taqcursos.support@taqtile.com.br'} />
        <VSeparator responsive={true} />
      </Col>
    </Row>

    <VSeparator responsive={true} />
  </VBox>
)
