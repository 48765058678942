import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';
import { RouterProps } from 'react-router';
import { Hero } from '../../atomic/atm.hero';
import { H1 } from '../../atomic/atm.typography';
import { Ul } from '../../atomic/atm.ul';
import { CourseItem } from '../../atomic/mol.course-item';
import { Asset } from '../../atomic/obj.asset';
import { VSeparator } from '../../atomic/obj.box';
import { Col, Grid, Row } from '../../atomic/obj.grid';
import { mapTrailConstants } from '../../atomic/obj.trail-utils';
import { Footer } from '../../atomic/org.footer';
import { Query } from '../../generated/graphql';

export const HomePage: React.FC<RouterProps> = (props) => {
  //TODO create graphql interface for schema
  const { data } = useQuery<{ Courses: Query['Courses'] }>(COURSES_QUERY);
  const trails = data?.Courses;

  return (
    <Grid fluid={true} expanded={true}>
      <Row mb={true}>
        <Col xs={12}>
          <Hero url={Asset.Image.HomeBg}>
            <VSeparator />
            <img src={Asset.Image.Logo} alt={'Logo do Taqcursos'} style={{ maxWidth: '70%' }} />
            <VSeparator />
            <VSeparator />
            <H1>Cursos eternamente em construção e gratuitos!</H1>
            <VSeparator />
          </Hero>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Row center={'xs'}>
            <Col xs={11} md={9}>
              <Ul>
                {trails?.map((trail) => {
                  const trailConstants = mapTrailConstants(trail.id);

                  return (
                    <CourseItem
                      name={trail.title}
                      description={trail.caption}
                      key={trail.id}
                      image={trailConstants.image}
                      to={`course/${trail.id}`}
                      color={trailConstants.color}
                    />
                  );
                })}
              </Ul>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Footer />
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};

const COURSES_QUERY = gql`
  query Courses {
    Courses {
      id
      title
      caption
      about
    }
  }
`;
