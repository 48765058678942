import * as React from 'react';

interface UserContextSate {
  user: UserProviderState;
  handleLogin: (name: string, token: string) => void;
  handleLogout: () => void;
}

export const UserContext = React.createContext<UserContextSate | null>(null);

interface UserProviderState {
  logged: boolean;
  name: string;
}

export enum UserStorge {
  TOKEN = 'token',
  USER = 'user'
}

export const UserProvider: React.FC<{}> = props => {

  const [user, setUser] = React.useState<UserProviderState>({name: '', logged: false});

  React.useEffect(() => {
    const initializeProvider = () => {
      const userJson = window.localStorage.getItem(UserStorge.USER);
      if(userJson) {
        setUser(JSON.parse(userJson));
      }
    }
    initializeProvider();
  }, [])

  const handleLogin = (newName: string, token: string) => {
    window.localStorage.setItem(UserStorge.TOKEN, token);
    window.localStorage.setItem(UserStorge.USER, JSON.stringify({ name: newName, logged: true }));
    setUser({name: newName, logged: true});
  }

  const handleLogout = () => {
    window.localStorage.removeItem(UserStorge.TOKEN);
    window.localStorage.removeItem(UserStorge.USER);
    setUser({name: '', logged: false});
  }

  return (
    <UserContext.Provider value={{ handleLogin, handleLogout, user }}>
      {props.children}
    </UserContext.Provider>
  );
}
