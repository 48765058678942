import styled from 'styled-components';
import { Color, FontWeight } from '../obj.constants';
import { H4 } from '../atm.typography';

export interface LessonCellStyledProps {
  selected?: boolean;
  highlightColor?: string;
}

export const LessonCellStyled = styled.div`
  width: 100%;
  background-color: ${(props: LessonCellStyledProps) => props.selected ?
    (props.highlightColor ? props.highlightColor : Color.Black) : Color.White};
`;

export const LessonCellImageStyled = styled.img`
  width: 100%;
  height: auto;
`

export const LessonCellImageWrapperStyled = styled.div`
  position: relative;
`

export const LessonCellBadgeWrapperStyled = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
`

interface LessonCellTitleStyledProps {
  selected?: boolean;
}

export const LessonCellTitleStyled = styled(H4)`
  font-weight: ${(props: LessonCellTitleStyledProps) => props.selected ? FontWeight.Bold : FontWeight.Normal};
`;
