import React from 'react';
import { VSeparator } from '../obj.box';
import { Link } from '../atm.button';
import { Body } from '../atm.typography';

export interface ContactProps {
  title: string;
  url: string;
}

export const Contact: React.FC<ContactProps> = props => (
  <>
    <Body><b>{props.title}</b></Body>
    <VSeparator half={true} />
    <a href={`mailto:${props.url}`}><Link>{props.url}</Link></a>
  </>
);
