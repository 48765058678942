export enum Border {
  Radius = '4px',
}

const BrandColor = {
  Purple: '#51136E',
}

const GrayScale = {
  Black: '#000000',
  White: '#ffffff',
  GrayXLight: '#F8F8F8',
  GrayLight: '#E6E6E6',
  Gray: '#828791',
  GrayDark: '#414141',
  GrayXDark: '#282D3C',
}

export const Color = {
  ...GrayScale,
  Primary: GrayScale.Black,
  Secondary: GrayScale.GrayXLight,
  CallToAction: BrandColor.Purple,
  Accessory: BrandColor.Purple,
}

// Breakpoints from bootstrap
// https://getbootstrap.com/

export enum Breakpoint {
  xs = '0px',
  sm = '576px',
  md = '768px',
  lg = '992px',
  xl = '1200px',
};

// https://css-tricks.com/8-digit-hex-codes/
export enum HexTransparency {
  Low = 'e6', // 90%
  Medium = '99', // 60%
}

export enum FontFamily {
  Primary = 'Chivo',
  Secondary = 'Overpass',
}

export enum FontSize {
  XSmall = '14px',
  Small = '16px',
  Medium = '18px',
  Large = '24px',
  XLarge = '32px',
  XXLarge = '56px',
}

export enum FontWeight {
  Bold = 'bold',
  Normal = 'normal',
  Light = 'light',
}

export enum Spacing {
  XSmall = 4,
  Small = 8,
  Medium = 12,
  Large = 16,
  XLarge = 24,
}

export const ResponsiveSpacing = 2;

export enum SocialMedia {
  Facebook = 'https://www.facebook.com/taqtilebrasil/',
  Instagram = 'https://www.instagram.com/taqtile',
  Medium = 'https://medium.com/@TaqtileBR',
}
