import { Color } from "../obj.constants";

export interface IcProps {
  color?: IcColorType;
  size?: IcSizeType;
}

type IcColorType = 'primary' | 'neutral' | 'accessory';

export const IcColors: { [key in IcColorType]: string } = {
  primary: Color.Primary,
  neutral: Color.Gray,
  accessory: Color.Accessory,
};

type IcSizeType = 'medium' | 'large';

export const IcSizes: { [key in IcSizeType]: string } = {
  medium: '16px',
  large: '24px',
};

export const IcDefaultProps: IcProps = { color: 'neutral', size: 'medium' };
