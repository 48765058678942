import styled from 'styled-components';
import { Color, FontFamily, FontWeight, FontSize, Spacing, Breakpoint } from '../obj.constants';

export const H1 = styled.h1`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.XLarge};
  margin: 0;

  @media (min-width: ${Breakpoint.md}) {
    font-size: ${FontSize.XXLarge};
  }
`;

export const H2 = styled.h2`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Large};
  text-transform: uppercase;
  margin: 0;
`;

export const H3 = styled.h3`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Small};
  margin: 0;
`;

export const H4 = styled.h4`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Small};
  color: ${Color.Black};
  margin: 0;
  margin-bottom: ${Spacing.XSmall}px;
`;

export const HomeCourseTitle = styled.h2`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Large};
  margin: 0 0 ${Spacing.XLarge}px 0;
  text-transform: uppercase;
  transition: all ease-in-out 0.3s;

  @media (min-width: ${Breakpoint.md}) {
    font-size: ${FontSize.XXLarge};
  }
`;

export const Body = styled.p`
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Medium};
  color: ${Color.GrayXDark};
  margin: 0;
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const BodySecondary = styled.p`
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.XSmall};
  color: ${Color.Black};
  margin: 0;
`;
