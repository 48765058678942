import styled from 'styled-components';
import { Breakpoint } from '../obj.constants';

const SMALL_IMG = '150px';
const MD_IMG = '200px';

export const HeaderImageStyled = styled.img`
  width: ${SMALL_IMG};

  @media (min-width: ${Breakpoint.md}) {
    width: ${MD_IMG};
  }
`;
