import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login';
import { Mutation } from '../../generated/graphql';
import { UserContext } from '../../providers/user.provider';
import { Link } from '../atm.button';
import { Divider } from '../atm.divider';
import { Asset } from '../obj.asset';
import { VBox, VSeparator } from '../obj.box';
import { Col, Grid, Row } from '../obj.grid';
import { HeaderImageStyled } from './header.component.style';
import firebase from 'firebase';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const Header: React.FC = () => {
  // TODO create analytics service
  const analytics = firebase.analytics();
  const userContext = React.useContext(UserContext);

  const handleCompletedMutation = (userAuth: Mutation['SignInGoogle']) => {
    analytics.logEvent('login', { method: 'Google' })
    userContext?.handleLogin(userAuth.user.name, userAuth.token);
  };

  // TODO handle login state
  const [signInGoogle] = useMutation<{ SignInGoogle: Mutation['SignInGoogle'] }, { googleToken: string }>(
    SIGN_IN_GOOGLE_MUTATION,
    {
      onCompleted: (data) => handleCompletedMutation(data.SignInGoogle),
    },
  );

  const handleGoogleAuthSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const loginResponse = response as GoogleLoginResponse;
    if (loginResponse?.tokenId) {
      signInGoogle({
        variables: {
          googleToken: loginResponse?.tokenId,
        },
      });
    }
  };

  const handleGoogleAuthFailure = () => {
    console.log('TODO');
    // TODO handle error
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { signIn: getGoogleToken } = useGoogleLogin({
    clientId: CLIENT_ID as string,
    onSuccess: handleGoogleAuthSuccess,
    onFailure: handleGoogleAuthFailure,
  });

  return (
    <>
      <VSeparator />

      <Grid fluid={true} xsExpanded={true}>
        <VBox mdNoGutter={true} lgNoGutter={true}>
          <Row bottom='xs'>
            <Col>
              <Link to='/'>
                <img src={Asset.Icon.Back} alt={'Botão de voltar'} />
              </Link>
            </Col>

            <Col xs={true}>
              <Link to='/'>
                <HeaderImageStyled src={Asset.Image.Logo} alt={'Logo do Taqcursos'} />
              </Link>
            </Col>

            {/* Hiding because we're not explaining why we use it */}
            {/* <Col reverse={true}>
              {userContext?.user.logged ? (
                <>
                  <Body>Olá, {userContext?.user.name}</Body>
                  <BodySecondary>
                    Não é você?&nbsp;{' '}
                    <Link as='span' onClick={userContext.handleLogout}>
                      Sair
                    </Link>
                  </BodySecondary>
                </>
              ) : (
                <Button to={'./'} type={'secondary'} wrap={true} onClick={getGoogleToken}>
                  Login pelo Google
                </Button>
              )}
            </Col> */}
          </Row>
        </VBox>
      </Grid>

      <VSeparator />
      <Divider />
    </>
  );
};

const SIGN_IN_GOOGLE_MUTATION = gql`
  mutation SignInGoogle($googleToken: String!) {
    SignInGoogle(googleToken: $googleToken) {
      token
      user {
        id
        googleId
        name
        email
        role
        imageUrl
      }
    }
  }
`;
