import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React from 'react';
import { Badge } from '../../atomic/atm.badge';
import { H1 } from '../../atomic/atm.typography';
import { VBox, VSeparator } from '../../atomic/obj.box';
import { Col, Row } from '../../atomic/obj.grid';
import { Youtube } from '../../atomic/obj.youtube';
import { LessonQueryVariables, Query } from '../../generated/graphql';
import { DetailSection } from './detail-section.component';
import { getTabItems, getVideoId } from './video.mapper';

// TODO - add error and loading components
export const VideoSection: React.FC<{ videoId: string; trail?: Query['Course']; onVideoEnd: () => void }> = ({
  videoId,
  trail,
  onVideoEnd,
}) => {
  // const userContext = React.useContext(UserContext);

  const { error: lessonQueryError, loading: lessonQueryLoading, data: lessonQueryData } = useQuery<
    { Lesson: Query['Lesson'] },
    LessonQueryVariables
  >(LESSON_QUERY, {
    variables: { id: +videoId },
    skip: !videoId,
  });

  // const { error: ratingQueryError, data: ratingQueryData, networkStatus: rateNetworkStatus } = useQuery<
  //   { QueryRating: Query['QueryRating'] },
  //   QueryRatingQueryVariables
  // >(RATING_QUERY, {
  //   notifyOnNetworkStatusChange: true,
  //   variables: { lessonId: +videoId },
  //   skip: !videoId,
  // });

  // const [updateRate, { loading: rateMutationLoading, error: rateMutationError }] = useMutation<
  //   { Lesson: Mutation['RateLesson'] },
  //   RateLessonMutationVariables
  // >(RATELESSON_MUTATION, {
  //   notifyOnNetworkStatusChange: true,
  //   // https://github.com/apollographql/apollo-client/issues/6070
  //   onError(error) {
  //     console.error('error', error.message);
  //   },
  //   refetchQueries: ['QueryRating'],
  // });

  const lesson = lessonQueryData?.Lesson;

  React.useEffect(() => {
    window?.scrollTo({ top: 0 });
  }, [videoId]);

  const tabItems = getTabItems(lesson, trail);

  return (
    <>
      {!lessonQueryError && !lessonQueryLoading && (
        <>
          <Youtube onVideoEnd={onVideoEnd} videoId={getVideoId(lesson?.video)} />
          <VSeparator />

          <VBox mdNoGutter={true} lgNoGutter={true}>
            {!!lesson && (
              <>
                <Row between='md' middle='xs'>
                  <Col xs={12} md={11}>
                    <H1>{lesson.title}</H1>
                  </Col>
                  <Col>
                    <Badge text={lesson.duration} type={'light'} />
                  </Col>
                </Row>
                {/* {ratingQueryData?.QueryRating && rateNetworkStatus !== NetworkStatus.loading && (
                  <Row end='xs'>
                    <Col>
                      <Likes
                        onClick={() =>
                          updateRate({
                            variables: { lessonId: +videoId, liked: ratingQueryData.QueryRating.liked ? null : true },
                          })
                        }
                        total={ratingQueryData.QueryRating.likes?.toString()}
                        isClickEnabled={
                          userContext?.user?.logged &&
                          rateNetworkStatus !== NetworkStatus.refetch &&
                          !rateMutationLoading
                        }
                        onClickNotEnabled={() => console.log('TODO')}
                      />
                    </Col>
                    <Col>
                      <Likes
                        dislikes
                        onClick={() =>
                          updateRate({
                            variables: {
                              lessonId: +videoId,
                              liked: ratingQueryData.QueryRating.liked === false ? null : false,
                            },
                          })
                        }
                        total={ratingQueryData.QueryRating.dislikes?.toString()!}
                        isClickEnabled={
                          userContext?.user?.logged &&
                          rateNetworkStatus !== NetworkStatus.refetch &&
                          !rateMutationLoading
                        }
                        onClickNotEnabled={() => console.log('TODO')}
                      />
                    </Col>
                  </Row>
                )} */}
              </>
            )}
            <VSeparator />

            <DetailSection items={tabItems} />
            <VSeparator responsive={true} />
          </VBox>
        </>
      )}
    </>
  );
};

const LESSON_QUERY = gql`
  query Lesson($id: Int!) {
    Lesson(id: $id) {
      id
      title
      description
      transcript
      references
      thumbnail
      video
      duration
    }
  }
`;

// const RATELESSON_MUTATION = gql`
//   mutation RateLesson($liked: Boolean, $lessonId: Int!) {
//     RateLesson(liked: $liked, lessonId: $lessonId) {
//       liked
//       likes
//       dislikes
//     }
//   }
// `;

// const RATING_QUERY = gql`
//   query QueryRating($lessonId: Int!) {
//     QueryRating(lessonId: $lessonId) {
//       liked
//       likes
//       dislikes
//     }
//   }
// `;
