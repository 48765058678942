import * as React from 'react';
import {
  MobileContentWrapperStyled,
  AccordionSectionChevronStyled,
  AccordionSectionTitleStyled,
} from './accordion-section-item.component.style';
import { AccordionSectionsContext } from './accordion-section.component';

interface AccordionSectionProps {
  title: string;
  index: number;
}

export const AccordionSectionItem: React.FC<AccordionSectionProps> = ({ title, children, index }) => {
  return (
    <AccordionSectionsContext.Consumer>
      {({ expanded, handleAccordionSectionItemTap, activeIndex }) => {
        const selected = activeIndex === index && expanded;
        return (
          <>
            <AccordionSectionTitleStyled selected={selected} onClick={handleAccordionSectionItemTap(index)}>
              {title}
              <AccordionSectionChevronStyled selected={selected} />
            </AccordionSectionTitleStyled>
            <MobileContentWrapperStyled selected={selected}>{children}</MobileContentWrapperStyled>
          </>
        );
      }}
    </AccordionSectionsContext.Consumer>
  );
};
