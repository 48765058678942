import styled from 'styled-components';
import { IcColors, IcSizes } from './ic.utils';

export const IcSvgStyled = styled.svg.attrs((props: any) => ({
  width: IcSizes[props.size],
  height: IcSizes[props.size],
  fill: IcColors[props.color],
  viewBox: '0 0 24 24',
}))`
  vertical-align: middle;
`;
