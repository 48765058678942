import * as React from 'react';
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Breakpoint, ResponsiveSpacing, Spacing } from '../obj.constants';

export interface RowProps {
  mb?: boolean;
  mt?: boolean;
  noGutter?: boolean;
}

const RowStyled = styled(FlexRow)`
  margin-bottom: ${(props: RowProps) => props.mb ? Spacing.XLarge + 'px' : 0 };
  margin-top: ${(props: RowProps) => props.mt ? Spacing.XLarge + 'px' : 0 };

  @media (min-width: ${Breakpoint.md}) {
    margin-bottom: ${(props: RowProps) => props.mb ? (Spacing.XLarge * ResponsiveSpacing) + 'px' : 0 };
    margin-top: ${(props: RowProps) => props.mt ? (Spacing.XLarge * ResponsiveSpacing) + 'px' : 0 };
  }

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${ROW_MARGIN}rem;
              margin-right: -${ROW_MARGIN}rem;
              `;
    } else {
      return '';
    }
  }}
`;

export interface GridProps {
  xsExpanded?: boolean;
  expanded?: boolean;
}

const GridStyled = styled(FlexGrid)`
  overflow: hidden;
  ${(props: GridProps) => props.expanded && `padding: 0;`};
  ${(props: GridProps) => props.xsExpanded && `
    @media screen and (max-width: ${GridSettings.breakpoints.sm}rem) {
      padding: 0;
    }`
  };
`;

export const Grid = props => <GridStyled {...props} />;
export const Col = props => <FlexCol {...props} />;
export const Row = props => <RowStyled {...props} />;

// From react-styled-flexboxgrid
export const GridSettings = {
  gridSize: 12,
  gutterWidth: 1, // rem
  outerMargin: 1, // rem
  breakpoints: {
    xs: 0,  // em
    sm: 32, // em
    md: 48, // em
    lg: 64, // em
  },
  container: {
    lg: 84,
  },
};

const ROW_MARGIN = GridSettings.outerMargin + GridSettings.gutterWidth / 2;
