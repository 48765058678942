import * as React from 'react';
import { IcSvgStyled } from './ic.component.style';
import { IcDefaultProps, IcProps } from './ic.utils';

export const IcThumbUp: React.FC<IcProps> = props => (
  <IcSvgStyled {...props}>
    <path
      d='M22.265 9.484A3.15 3.15 0 0019.85 8.35h-4.788l.588-1.501A4.336 4.336 0 0011.565 1a1.05 1.05 0 00-.955.62L7.618 8.35H5.15A3.15 3.15 0 002 11.5v7.35A3.15 3.15 0 005.15 22h13.367a3.15 3.15 0 003.097-2.583l1.334-7.35a3.15 3.15 0 00-.683-2.583zM7.25 19.9h-2.1c-.58 0-1.05-.47-1.05-1.05V11.5c0-.58.47-1.05 1.05-1.05h2.1v9.45zm12.317-.86l1.333-7.35a1.05 1.05 0 00-.241-.862 1.05 1.05 0 00-.809-.378h-4.788a2.1 2.1 0 01-1.963-2.835l.556-1.501a2.216 2.216 0 00-1.449-2.92L9.35 9.622V19.9h9.167a1.05 1.05 0 001.05-.862z'
      fill='inherit'
      fillRule='evenodd'
    />
  </IcSvgStyled>
);

IcThumbUp.defaultProps = { ...IcDefaultProps };
