import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing, HexTransparency } from '../obj.constants';
import { BadgeProps } from './badge.component';

const badgeBackgroundColors = {
  light: Color.GrayXLight,
  dark: Color.Primary + HexTransparency.Medium,
};

const badgeTextColors = {
  light: Color.Primary,
  dark: Color.Secondary,
};

export const BadgeStyled = styled.span`
  display: inline-block;
  border-radius: ${Border.Radius};
  background-color: ${(props: BadgeProps) => props.type ? badgeBackgroundColors[props.type] : Color.Secondary};
  align-self: flex-start;
  justify-content: center;
  margin: 3px 0px;
`;

export const BadgeTextStyled = styled.label`
  color: ${(props: BadgeProps) => props.type ? badgeTextColors[props.type] : Color.Primary};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  padding: ${Spacing.XSmall}px ${Spacing.Small}px;
`;
