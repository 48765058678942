import { Spacing, Color } from '../obj.constants';
import styled, { css } from 'styled-components';
import { H3 } from '../atm.typography';

interface AccordionSectionStyledProps {
  selected: boolean;
}

const AnimationDuration = '0.5s';

const AccordionSectionItemHeaderHeight = 44;

const AccordionSectionExpandedExtraHeight = 300;

const UnderlineCss = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${(props: AccordionSectionStyledProps) => (props.selected ? 1 : 0.6)};
    transition: opacity ${AnimationDuration};
    height: 4px;
    background-color: ${Color.Black};
  }
`;

export const AccordionSectionTitleStyled = styled(H3)`
  cursor: pointer;
  padding-top: ${Spacing.Medium}px;
  width: 100%;
  opacity: ${(props: AccordionSectionStyledProps) => (props.selected ? 1 : 0.6)};
  transition: opacity ${AnimationDuration};
  color: ${Color.Black};
  padding-bottom: ${Spacing.Medium}px;
  ${UnderlineCss}
`;
export const AccordionSectionChevronStyled = styled.div`
  position: absolute;
  top: ${Spacing.Medium}px;
  right: ${Spacing.XSmall}px;
  &::before {
    opacity: ${(props: AccordionSectionStyledProps) => (props.selected ? 1 : 0.6)};
    content: '';
    border: solid ${Color.Black};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(${(props: AccordionSectionStyledProps) => (props.selected ? '-135deg' : '45deg')});
    transition: transform ${AnimationDuration};
  }
`;

interface AccordionSectionWrapperStyledProps {
  expanded: boolean;
  numberOfItems: number;
}

export const AccordionSectionsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props: AccordionSectionWrapperStyledProps) =>
    props.numberOfItems * AccordionSectionItemHeaderHeight +
    (props.expanded ? AccordionSectionExpandedExtraHeight : 0)}px;
  transition: height ${AnimationDuration};
  width: 100%;
`;

export const MobileContentWrapperStyled = styled.div`
  overflow: auto;
  flex: ${(props: AccordionSectionStyledProps) => (props.selected ? 1 : 0)};
  transition: flex ${AnimationDuration};
`;
