import React from "react";
import ApolloClient from 'apollo-boost';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./assets/fonts/fonts.css";
import { HomePage } from "./modules/home/home.page";
import { AppPath } from "./routes-list";
import { VideoPage } from "./modules/detail/video.page";
import { ApolloProvider } from '@apollo/react-hooks';
import { UserProvider, UserStorge } from "./providers/user.provider";
import firebase from 'firebase/app';

function NotFound() {
  return <h2>Page not found</h2>;
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_BASE_URL,
  request: async (operation) => {
    const token = window.localStorage.getItem(UserStorge.TOKEN);
    // TODO check if token is expired
    if(token){
      operation.setContext({
        headers: {
          authorization: token
        }
      });
    }
  }
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <Router>
          <Switch>
            <Route exact path={AppPath.Home} component={HomePage} />
            <Route path={AppPath.Course.Item} component={VideoPage} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </UserProvider>
    </ApolloProvider>
  );
}

export default App;
