import React, { FC } from 'react';
import { ButtonStyled } from './button.component.style';

export interface ButtonProps {
  to: string;
  type: 'primary' | 'secondary';
  disabled?: boolean;
  wrap?: boolean;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = props => (
  <ButtonStyled {...props}>
    {props.children}
  </ButtonStyled>
)
