import React from 'react';
import { AccordionSectionItem } from './accordion-section-item.component';
import { AccordionSectionsWrapperStyled } from './accordion-section-item.component.style';

interface AccordionSectionProps {
  activeIndex: number;
  onIndexChanged: (index: number) => void;
}

interface AccordionSectionsState {
  expanded: boolean;
  handleAccordionSectionItemTap: (index: number) => () => void;
  activeIndex: number;
}

export const AccordionSectionsContext = React.createContext<AccordionSectionsState>({} as AccordionSectionsState);

const _AccordionSections: React.FC<AccordionSectionProps> = (props) => {
  const { onIndexChanged } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionSectionItemTap = (index) => () => {
    if (index === props.activeIndex) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
    onIndexChanged(index);
  };

  return (
    <AccordionSectionsWrapperStyled numberOfItems={React.Children.count(props.children)} expanded={expanded}>
      <AccordionSectionsContext.Provider
        value={{
          handleAccordionSectionItemTap,
          expanded,
          activeIndex: props.activeIndex,
        }}
      >
        {props.children}
      </AccordionSectionsContext.Provider>
    </AccordionSectionsWrapperStyled>
  );
};

export const AccordionSections = Object.assign(_AccordionSections, { Item: AccordionSectionItem });
