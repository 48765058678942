import * as React from 'react';
import { YoutubeStyled, YoutubeWrapperStyled } from './youtube.component.style';

export interface YoutubeProps {
  videoId: string | undefined;
  onVideoEnd: () => void;
}

export const Youtube: React.FC<YoutubeProps> = (props) => {
  return (
    <YoutubeWrapperStyled>
      <YoutubeStyled onEnd={props.onVideoEnd} videoId={props.videoId} opts={{ playerVars: { autoplay: 1 } }} />
    </YoutubeWrapperStyled>
  );
};
