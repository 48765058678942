import * as React from 'react';
import { Markdown } from '../../atomic/mol.markdown';
import { AccordionSections } from '../../atomic/mol.accordion-section/accordion-section.component';
import { Tabs } from '../../atomic/mol.tabs';
import { VSeparator } from '../../atomic/obj.box';
import { Col, Row } from '../../atomic/obj.grid';

interface DetailSectionProps {
  items: TabItem[];
}

export interface TabItem {
  label: string;
  content: string;
}

export const DetailSection: React.FC<DetailSectionProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { items } = props;

  return (
    <>
      <Row>
        <Col md={12} xs={false}>
          <Tabs
            tabItems={items?.map((tab) => tab?.label)}
            activeIndex={selectedIndex}
            onIndexChanged={setSelectedIndex}
          />
          <VSeparator />
          <Markdown>{items?.[selectedIndex]?.content}</Markdown>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={false}>
          <AccordionSections activeIndex={selectedIndex} onIndexChanged={setSelectedIndex}>
            {items?.map((item, index) => {
              return (
                <AccordionSections.Item title={item.label} key={index} index={index}>
                  <Markdown>{item.content}</Markdown>
                </AccordionSections.Item>
              );
            })}
          </AccordionSections>
        </Col>
      </Row>
    </>
  );
};
