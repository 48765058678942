import { Query } from '../../generated/graphql';
import { TabItem } from './detail-section.component';

export const getTabItems = (video?: Query['Lesson'], trail?: Query['Course']) => {
  const tabItems: TabItem[] = [
    {
      label: 'Sobre o curso',
      content: trail?.about ?? '',
    },
    {
      label: 'Transcrição do vídeo',
      content: video?.transcript ?? '',
    },
    {
      label: 'Materiais',
      content: video?.references ?? '',
    },
  ];
  return tabItems;
};

export const getVideoId = (url: string | undefined) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match?.[2] || '';
};
