import React, { FC } from 'react';
import { LessonCellStyled, LessonCellImageStyled, LessonCellImageWrapperStyled, LessonCellBadgeWrapperStyled, LessonCellTitleStyled } from './lesson-cell.component.style';
import { BodySecondary } from '../atm.typography';
import { Badge } from '../atm.badge';
import { HBox, VSeparator } from '../obj.box';

interface LessonCellProps {
  title: string;
  description: string;
  source: string;
  badgeText?: string;
  selected?: boolean;
  highlightColor?: string;
}

export const LessonCell: FC<LessonCellProps> = props => {
  return (
    <LessonCellStyled selected={props.selected} highlightColor={props.highlightColor}>
      <VSeparator />
      <HBox>
        <HBox.Separator />
        <HBox.Item>
          <LessonCellImageWrapperStyled>
            <LessonCellImageStyled src={props.source} />
            {props.badgeText && (
              <LessonCellBadgeWrapperStyled>
                <Badge text={props.badgeText} type={'dark'} />
              </LessonCellBadgeWrapperStyled>
            )}
          </LessonCellImageWrapperStyled>
        </HBox.Item>
        <HBox.Separator />
        <HBox.Item double={true}>
          <LessonCellTitleStyled selected={props.selected}>{props.title}</LessonCellTitleStyled>
          <BodySecondary>{props.description}</BodySecondary>
        </HBox.Item>
        <HBox.Separator />
      </HBox>
      <VSeparator />
    </LessonCellStyled>
  );
};
