import React from 'react';
import { NavLink } from 'react-router-dom';
import { ScrollListStyled } from '../../atomic/atm.scroll-list';
import { H2 } from '../../atomic/atm.typography';
import { Ul } from '../../atomic/atm.ul';
import { LessonCell } from '../../atomic/mol.cell';
import { Asset } from '../../atomic/obj.asset';
import { VBox, VSeparator } from '../../atomic/obj.box';
import { Query } from '../../generated/graphql';

interface ListComponentProps {
  items?: Query['Lesson'][];
  trailId: string;
  videoId: string;
  title?: string;
  highlightColor?: string;
}

export const ListComponent: React.FC<ListComponentProps> = (props) => {
  return (
    <ScrollListStyled>
      <Ul>
        <VBox>
          <VSeparator />
          <H2>{props.title}</H2>
          <VSeparator />
        </VBox>

        {props.items?.map((lesson) => {
          const { id, title, description, duration, thumbnail } = lesson;
          return (
            <li key={id}>
              <NavLink to={`/course/${props.trailId}?videoId=${id}`}>
                <LessonCell
                  title={title}
                  description={description}
                  source={thumbnail}
                  badgeText={duration}
                  selected={`${lesson?.id}` === props.videoId}
                  highlightColor={props.highlightColor}
                />
              </NavLink>
            </li>
          );
        })}

        {/* TODO INT */}
        {false && (
          <li>
            <NavLink to={`/course/2/challenge`}>
              <LessonCell
                title='Desafio'
                description='Agora é a sua vez.'
                source={Asset.Image.Challenge}
                selected={'challenge' === props.videoId}
                highlightColor={props.highlightColor}
              />
            </NavLink>
          </li>
        )}
      </Ul>
    </ScrollListStyled>
  );
};
