import React, { FC } from 'react';
import * as H from 'history';
import { LinkStyled } from './link.component.style';
import { Link as LinkDom } from 'react-router-dom';

interface LinkProps extends React.AnchorHTMLAttributes<any>{
  to?: H.LocationDescriptor;
  as?: any;
}

export const Link: FC<LinkProps> = ({ to, ...props }) => {
  const linkProps = to ? { to, as: LinkDom } : null;
  return <LinkStyled {...linkProps} {...props} />;
};
