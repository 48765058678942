import styled, { css } from 'styled-components';
import { Color, Spacing } from '../obj.constants';

export const TabStyled = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  cursor: pointer;
`;

interface TabsStyledProps {
  selected: boolean;
}

const UnderlineCss = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: ${(props: TabsStyledProps) => props.selected ? '0' : 'auto'};
    height: 4px;
    background-color: ${Color.Black};
  }
`;

export const TabItemStyled = styled.div`
  padding: ${Spacing.Medium}px;
  opacity: ${(props: TabsStyledProps) => props.selected ? 1 : 0.6};
  display: flex;
  align-items: center;
  color: ${Color.Black};
  ${UnderlineCss}
`;
